import React, { useState } from "react";
import axios from "axios";
import AdminComponent from "../../Componet/Layout/AdminComponent";

function UserSeassion() {
  const [email, setEmail] = useState("");
  const [sessionData, setSessionData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSearch = async () => {
    try {
      const response = await axios.get(
        `https://earning-site-fll-backend-code.onrender.com/api/v1/autoearnstart/user-sessions?email=${email}`
      );

      if (response.data.success) {
        setSessionData(response.data); // API se session data set karo
        setErrorMessage(""); // Error message ko clear kar do agar data milta hai
      } else {
        setSessionData(null);
        setErrorMessage(response.data.message || "No session found.");
      }
    } catch (error) {
      console.error("Error fetching sessions:", error);
      setSessionData(null);
      setErrorMessage("An error occurred while fetching sessions.");
    }
  };

  return (
    <div>
      <AdminComponent />
      <h2>User Session Search</h2>
      <input
        type="text"
        placeholder="Enter email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button onClick={handleSearch}>Search</button>

      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}

      {sessionData && (
        <div>
          <h3>Session Details for: {sessionData.email}</h3>
          <p>Total Earned: {sessionData.totalEarned}</p>
          <p>Total Sessions: {sessionData.totalSessions}</p>
          {sessionData.totalSessions > 0 ? (
            <ul>
              {sessionData.sessionDetails.map((session) => (
                <li key={session.sessionId}>
                  <p>Session ID: {session.sessionId}</p>
                  <p>
                    Start Time: {new Date(session.startTime).toLocaleString()}
                  </p>
                  <p>Hourly Rate: {session.hourlyRate}</p>
                </li>
              ))}
            </ul>
          ) : (
            <p>No sessions available.</p>
          )}
        </div>
      )}
    </div>
  );
}

export default UserSeassion;
