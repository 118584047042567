import React, { useState, useEffect } from "react";
import { useAuth } from "../../Context/auth";
import axios from "axios";
import "../../Styles/AutoEarn.css";
import Layout from "./../../Componet/Layout/Layout";
import { FaRocket } from "react-icons/fa";

import logo from "../../Assets/sitelogo.png";

function AutoEarn() {
  const [auth] = useAuth();
  const [earnings, setEarnings] = useState(null);

  const [hourlyEarnings, setHourlyEarnings] = useState(0);
  const [totalSessionEarnings, setTotalSessionEarnings] = useState(0);
  const [claimedSessionsCount, setClaimedSessionsCount] = useState(0);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(null);
  const [message, setMessage] = useState("");

  const displayCurrency = "Rs";

  const checkExistingSession = async () => {
    try {
      const response = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/autoearnstart/check-active-session"
      );

      if (response.data.hasActiveSession) {
        const remainingTime = response.data.remainingTime;
        const timeParts = remainingTime.match(/\d+/g);
        let totalSeconds = 0;
        if (timeParts) {
          const [hours, minutes, seconds] = [
            parseInt(timeParts[0] || "0"),
            parseInt(timeParts[1] || "0"),
            parseInt(timeParts[2] || "0"),
          ];
          totalSeconds = hours * 3600 + minutes * 60 + seconds;
        }
        setTimer(totalSeconds);
      } else {
        setTimer(null);
      }
    } catch (err) {
      console.error("Failed to check session", err);
    }
  };

  const startAutoEarn = async () => {
    try {
      setMessage("Earnings process started, please wait...");
      setTimeout(() => setMessage(""), 2000);

      const response = await axios.post(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/autoearnstart/start"
      );
      const { nextClaimTime } = response.data;
      const remainingTime = Math.ceil(
        (new Date(nextClaimTime) - new Date()) / 1000
      );
      setTimer(remainingTime);
    } catch (err) {
      setError("Failed to start auto-earn process.");
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const formatTime = (seconds) => {
    if (seconds === null || seconds <= 0) return "0 min 0 sec";
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} sec`;
  };

  useEffect(() => {
    checkExistingSession();
    fetchHourlyEarn();
    fetchTotalSessionEarnings();
  }, []);
  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const response = await axios.get(
          "https://earning-site-fll-backend-code.onrender.com/api/v1/users/earnings",
          {}
        );
        setEarnings(response.data);
      } catch (err) {
        setError("Failed to fetch earnings.");
      } finally {
        setLoading(false);
      }
    };

    fetchUserEarnings();
  }, []);
  const fetchHourlyEarn = async () => {
    try {
      const response = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/autoearnstart/hourly-earnings"
      );
      setHourlyEarnings(response.data.hourlyEarnings);
    } catch (err) {
      setError("Failed to fetch hourly earnings.");
    }
  };

  const fetchTotalSessionEarnings = async () => {
    try {
      const response = await axios.get(
        "https://earning-site-fll-backend-code.onrender.com/api/v1/autoearnstart/total-auto-earn"
      );
      setTotalSessionEarnings(response.data.totalEarned);
      setClaimedSessionsCount(response.data.totalSessions);
      setLoading(false); // Set loading to false after successful fetch
    } catch (err) {
      setError("Failed to fetch total session earnings.");
    }
  };
  const formatAmount = (amount) => {
    return parseFloat(amount)
      .toFixed(4)
      .replace(/(\.0+|(?<=\.\d)0+)$/, "");
  };
  return (
    <Layout>
      <div
        className="dashboard-container bg-light text-center py-4 p-5 position-relative"
        style={{
          height: "auto",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginTop: "130px",
        }}
      >
        <div className="d-flex justify-content-between align-items-center h-100 flex-wrap">
          <div>
            <h1
              style={{ fontSize: "35px", fontWeight: "bold ", color: "black" }}
            >
              AUTO EARN
            </h1>
          </div>
          <div className="d-flex align-items-center details-container">
            <img
              src={logo}
              alt="Dashboard Logo"
              className="dashboard-logo"
              style={{
                borderRadius: "10px",
                width: "100px",
                height: "100px",
                marginLeft: "20px",
              }}
            />
            <div
              className="mt-5 mb-5 p-5"
              style={{
                background: "white",
                padding: "10px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                marginRight: "30px",
              }}
            >
              <p className="text-muted mb-1">
                <span
                  className="fw-bold"
                  style={{ fontSize: "30px", color: "black" }}
                >
                  👨 {auth?.user?.username}
                </span>
              </p>
              <p className="text-muted mb-1">
                <span className="fw-bold" style={{ color: "black" }}>
                  Earnings:
                </span>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "black" }}>
                    {formatAmount(earnings ? earnings.earnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
              <p className="text-muted mb-0">
                <span className="fw-bold" style={{ color: "black" }}>
                  Total Earnings:
                </span>{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span style={{ color: "black" }}>
                    {formatAmount(earnings ? earnings.totalEarnings : "0")}{" "}
                    {displayCurrency}
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="alert alert-success"
        role="alert"
        style={{ padding: "20px", borderRadius: "10px", marginTop: "20px" }}
      >
        <h2
          style={{ color: "black", textAlign: "center", marginBottom: "20px" }}
        >
          How we start working
        </h2>
        <ol style={{ fontSize: "16px", lineHeight: "1.6" }}>
          <li>
            Click on start button and your timer is start wait for 1 hour.
          </li>
          <li>
            After 1 hour complete the hourly amount automatic add in your
            account and after your can start again
          </li>
          <li>
            In the case if you want to increase your hourly income the we add
            booster button that increase your hourly income
          </li>
          <li>
            Currently this is not avaliable soon we active booster button after
            that you can boost your hourly income
          </li>
        </ol>
      </div>
      <div>
        <marquee>
          <div className="earnings-info-container">
            <div className="earnings-circle">
              <h4>
                Hourly Earnings:{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    {formatAmount(hourlyEarnings)} {displayCurrency}
                  </span>
                )}
              </h4>
            </div>
            <div className="earnings-circle">
              <h4>
                Total Earnings from Sessions:{" "}
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>
                    {formatAmount(totalSessionEarnings)} {displayCurrency}
                  </span>
                )}
              </h4>
            </div>
            <div className="earnings-circle">
              <h4>Total Claimed Sessions</h4>
              <p>
                {loading ? (
                  <span>Loading...</span>
                ) : (
                  <span>{claimedSessionsCount}</span>
                )}
              </p>
            </div>
          </div>
        </marquee>
      </div>

      {message && <div className="message-container">{message}</div>}

      <div className="auto-earn-container">
        <div className="circle-background">
          {timer !== null ? (
            <button className="start-button" disabled>
              {formatTime(timer)} remaining
            </button>
          ) : (
            <button className="start-button" onClick={startAutoEarn}>
              Start Earning
            </button>
          )}
        </div>

        <div style={{ position: "relative" }}>
          <button className="boost-button">
            <FaRocket /> Boost Now
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default AutoEarn;
